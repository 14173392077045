@import '~bootstrap/scss/bootstrap';
@import 'assets/fonts/fonts';
@import './styles/variables';
@import './styles/mixins';
@import '../../../libs/wallex-core/src/styles/overide-primeng/index';
@import '../../../libs/wallex-core/src/styles/common';
@import '../../../libs/wallex-core/src/styles/constants';
@import '../../../libs/wallex-core/src/styles/forms';
@import '../../../libs/wallex-core/src/styles/buttons';
@import '../../../libs/wallex-core/src/styles/cards';
@import '../../../libs/wallex-core/src/styles/typography';
@import '../../../libs/wallex-core/src/styles/services';

html,
body {
  height: 100%;
}

.pt-8 {
  padding-top: 8px;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 0%) !important;
}

body {
  color: var(--main-text);

  font-family: var(--font-base-regular);

  .loader-container {
    position: absolute;
    z-index: 2;

    width: 100%;
    height: 100%;

    background-image: url('/assets/loader.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  .subheader-container {
    margin-bottom: 20px;
  }

  .flex-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  .button-area {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  }

  .notification-container {
    margin-bottom: 32px;
    padding: 15px 16px;

    border: none;
    border-radius: $border-radius;
    background-color: var(--notification-background);

    .title2 {
      margin: 0 0 6px;
    }

    @include text(var(--font-base-regular), $font-size-regular, 24px, 400);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    opacity: 0.4;
    border-radius: 100px;
    background: var(--scrollbar-color);
  }
}

.is-loading,
.is-loading:enabled:hover {
  background-image: url('./assets/small-loader.svg');
  background-repeat: no-repeat;
  background-position: center center;

  font-size: 0;
}

.is-loading-block {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border-radius: $border-radius $border-radius 0 0;

  background-color: rgb(var(--main-text) 0.1);
  background-image: url('./assets/small-loader.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.disabled-button {
  pointer-events: none;

  color: #888989;
  background: var(--grey);
}

.p-carousel-container {
  position: relative;

  .p-carousel-prev,
  .p-carousel-next {
    position: absolute;
    z-index: 1;

    width: 40px !important;
    height: 40px !important;

    background: var(--base-one) !important;

    .pi-chevron-left::before,
    .pi-chevron-right::before {
      display: block;

      width: 20px;
      height: 20px;

      content: '';

      background-image: url('./assets/buttons/send.svg');
      background-size: contain;
    }
  }

  .p-carousel-prev {
    left: 0;

    .pi-chevron-left {
      transform: rotate(180deg);
    }
  }

  .p-carousel-next {
    right: 0;
  }
}

.hint-message {
  padding: 14px 16px;

  color: var(--main-text);
  border-radius: $border-radius;
  background: var(--hint-background);

  @include text(var(--font-base-regular), $font-size-small, 22px, 400);
}

.--bg-orange {
  background: var(--light-orange);
}

.green {
  color: var(--text-green) !important;
}

.red {
  color: var(--main-red);
}

.rate-green {
  color: var(--text-green);
  background: url('./assets/arrow-up.svg') no-repeat center left;
}

.rate-red {
  color: var(--main-red);
  background: url('./assets/arrow-down.svg') no-repeat center left;
}

ngx-skeleton-loader .loader.progress {
  background: #d3d0d0 no-repeat;
}

button.activity__withdraw.is-flexible.p-component,
a.view-explorer {
  color: #111314;
  border-color: #fff;
  background: #fff;
}
