@import '../../../../libs/wallex-core/src/styles/variables';
@import '../../../../libs/wallex-core/src/styles/mixins';

:root {
  --base-one: #fff;

  //backgrounds and borders
  --body-background: #010110;
  --item-border: #36424b;
  --plate-border: #11192d;
  --item-background: #11192d;
  --item-disabled-background: #1c2c43;
  --item-secondary-background: #1c2c43;
  --dropdown-search-background: #161f35;
  --section-border: #273037;
  --main-text: #fff;
  --secondary-text: #9b9ea4;
  --border-secondary: #1c2c43;
  --scrollbar-color: rgb(#{hex-to-rgb(#e2e3e7)} / 60%);
  --separator-color: #979797;
  --widget-border: #0075cd;
  --safari-scroll-background: rgb(#{hex-to-rgb(#e2e3e7)} / 60%);

  //intercom color
  --intercom-color: #0075cd;

  //menu text colors
  --menu-item: #0075cd;
  --menu-item-hover: #fff;
  --menu-icon: brightness(0) saturate(100%) invert(31%) sepia(72%) saturate(4055%) hue-rotate(190deg) brightness(92%) contrast(101%);
  --menu-icon-hover: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(263deg) brightness(100%) contrast(103%);

  //icons
  --icon-background: #0075cd;
  --icon-color: var(--menu-icon-hover);
  --grey-text-icons: #767676;
  --icon-danger-background: #f9e6e6;
  --icon-danger-color: brightness(0) saturate(100%) invert(19%) sepia(95%) saturate(2326%) hue-rotate(346deg) brightness(97%) contrast(85%);
  --icon-inactive: #a7a7a7;
  --hover-filter: invert(27%) sepia(95%) saturate(1554%) hue-rotate(186deg) brightness(100%) contrast(102%);

  //wallet types
  --fiat-type: #fff;
  --crypto-type: #0075cd;
  --saving-type: #d3e5ff;
  --market-chart: #0075cd;

  //cards
  --card-text: #fff;
  --card-text-title: rgb(#{hex-to-rgb(#fff)} / 60%);
  --card-cvv: #111314;
  --logo-width: 12.9em; // only in em
  --fields-card: unset;
  --card-logo: unset;
  --card-background: var(--primary-gradient);

  // app colors
  --hint-background: #0a2b4f;
  --notification-background: #0a2b4f;
  --gradient-color1: #010210;
  --gradient-color2: #094587;
  --main-red: #cc3030;
  --grey: #1b2848;
  --grey-background: #1c2c43;
  --grey-easy: #273037;
  --grey-primary: #888989;
  --light-red: #392424;
  --light-orange: #372d20;
  --dark-green: #205e3b;
  --text-green: #2aac66;
  --light-green: #1a312b;
  --light-blue: #0075cd;
  --orange: #e17c05;
  --extra-light: #0b1928;
  --hover-primary: #026cbd;
  --primary-text: #0075cd;
  --button-primary: #0075cd;
  --other-blue: #1e55b2;

  // gradients
  --primary-gradient: linear-gradient(136.32deg, var(--gradient-color1) 0%, var(--gradient-color2) 97.8%);

  // filters
  --to-light-blue: brightness(0) saturate(100%) invert(23%) sepia(81%) saturate(1452%) hue-rotate(202deg) brightness(100%) contrast(99%);
  --image-hover-filter: brightness(0) saturate(100%) invert(28%) sepia(60%) saturate(2021%) hue-rotate(182deg) brightness(104%) contrast(111%);
  --to-black: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(7477%) hue-rotate(68deg) brightness(98%) contrast(107%);

  //sizes
  --auth-logo-height: 40px;
  --dashboard-logo-width: 100px;
  --dashboard-logo-mobile-width: 36.46px;

  //fonts
  --font-base-light: poppins-light;
  --font-base-regular: poppins-regular;
  --font-base-medium: poppins-medium;
  --font-base-bold: poppins-bold;
  --font-base-semiBold: poppins-semibold;
  --font-sans-regular: opensans-regular;
  --font-sans-semiBold: opensans-semibold;
  --font-sans-medium: opensans-medium;
  --font-kanit-light: kanit-light;
  --font-kanit-bold: kanit-bold;
}
